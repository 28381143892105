import loadScript from "../../../utils/loadScript";

export default function setUpPayments(
    createMicroformTokenMutation,
    createCardinalTokenMutation,
    placeOrderWithCcaMutation,
    redeemQuotationWithCcaMutation,
    microformState,
    setMicroformState,
    cart,
    setCardMeta=null,
    checkout={},
    setCheckout=null,
) {
    console.log("setUpPayments");

    const basicPayload = checkout?.redeemQuote
        ? { quotationId: checkout.quotationId, dpCode: checkout.dpCode }
        : { cart_id: cart.id };

    loadScript("https://songbirdstag.cardinalcommerce.com/edge/v1/songbird.js").then(() => {
        loadScript("/flex-microform.min.js").then(() => {
            createMicroformTokenMutation({
                variables: basicPayload
            }).then((data) => {
                const microformRequestToken = data.data.createMicroformToken.token

                setUpFlex(
                    microformRequestToken,
                    microformState,
                    setMicroformState,
                    setCardMeta,
                );

                createCardinalTokenMutation({
                    variables: {
                        requestData: {
                            ...basicPayload,
                            token: microformRequestToken,
                            cc_type: "VI",
                            exp_date: "06-2050"
                        }
                    }
                }).then(data => {
                    setUpCardinal(
                        data.data.createCardinalToken.token, 
                        (checkout?.redeemQuote ? redeemQuotationWithCcaMutation : placeOrderWithCcaMutation), 
                        cart, 
                        checkout, 
                        setCheckout
                    );
                })
            })
        }).catch((error) => {
            console.log('Error when loading flexState-microform.min.js', error);
        });
    })
}

function setUpFlex(requestToken, microformState, setMicroformState, setCardMeta) {
    const flexStyles = {
        'input': {
            'font-size': '1rem',
            'font-family': 'HelveticaNow, Helvetica, Arial, sans-serif',
            'color': '#4a4a4a'
        },
        '::placeholder': { 'color': '#d4d4d4' },
        ':focus': { 'color': '#4a4a4a' },
        ':disabled': { 'cursor': 'not-allowed' },
        'valid': { 'color': '#17BB75' },
        'invalid': { 'color': '#EF4135' }
    };

    const newFlex = new Flex(requestToken)
    const newMicroform = newFlex.microform({styles: flexStyles})

    const number = newMicroform.createField('number', {placeholder: "Enter card number"})
    number.on('change', data => {
        if (setCardMeta && data.card[0]) setCardMeta(data.card[0]);
    });
    number.load("#number-container");
    const securityCode = newMicroform.createField('securityCode', {placeholder: "Enter security code"})
    securityCode.load("#securityCode-container")

    setMicroformState(microformState => ({...microformState, microform: newMicroform}));
}

function setUpCardinal(token, retryOrderMutation, cart, checkout, setCheckout) {
    const basicPayload = checkout?.redeemQuote
        ? { quotationId: checkout.quotationId, dpCode: checkout.dpCode }
        : { cart_id: cart.id };

    Cardinal.configure({
        logging: {
            debug: 'verbose'
        }
    });

    Cardinal.setup('init', {
        jwt: token,
    })

    Cardinal.on('payments.setupComplete', function () {
        console.log("payment setup is complete")
    });

    Cardinal.on("payments.validated", function (data, jwt) {
        console.log("payments validated", data)
        // see https://cardinaldocs.atlassian.net/wiki/spaces/CC/pages/557065/Songbird.js#Songbird.js-payments.validated
        if (data.ErrorNumber === 0) {
            console.log("pvalidate success")
        }
        else {
            console.log("3d-secure failure")
        }

        console.log("cca response jwt", jwt)

        retryOrderMutation({
            variables: {
                ccaData: {
                    ...basicPayload,
                    jwt: jwt
                },
                orderData: {
                    ...basicPayload
                }
            }
        }).then(data => {
            console.log("retried order mutation", data)

            // TODO: Check placeOrder mutation response and display success etc
        }).catch(error => {
            // checkout has old state in it but that's ok we don't want it anyway
            setCheckout({
                ...checkout,
                submitStatus: (typeof error === 'string') ? error : error.message,
            });
        })
    });
}
