import React, {useEffect, useState, useLayoutEffect, useRef} from "react"
import {useCartContext} from "../../../contexts/CartContext";
import setUpPayments from "./setup";
import {useCreateCardinalToken, useCreateMicroformToken, usePlaceOrder, usePlaceOrderWithCca, useRedeemQuotationWithCca} from "./hooks/useFlex";

export default function FlexMicroformPayment() {
    const [microformState, setMicroformState] = useState({microform: {}});
    const [expirationMonth, setExpirationMonth] = useState("01")
    const [expirationYear, setExpirationYear] = useState("2021")

    const handleExpirationMonthChange = (event) => {
        setExpirationMonth(event.target.value)
    }
    const handleExpirationYearChange = (event) => {
        setExpirationYear(event.target.value)
    }

    const {cart} = useCartContext()

    console.log("cart id", cart.id)

    const [createMicroformTokenMutation] = useCreateMicroformToken()
    const [placeOrderMutation] = usePlaceOrder()
    const [createCardinalTokenMutation] = useCreateCardinalToken()
    const [placeOrderWithCcaMutation] = usePlaceOrderWithCca()
    const [redeemQuotationWithCcaMutation] = useRedeemQuotationWithCca();

    useLayoutEffect(() => {
        setUpPayments(
            createMicroformTokenMutation,
            createCardinalTokenMutation,
            placeOrderWithCcaMutation,
            redeemQuotationWithCcaMutation,
            microformState,
            setMicroformState,
            cart
        )
    }, [])

    console.log("microform state", microformState)

    const microform = microformState.microform

    const handleSubmit = (event) => {
        event.preventDefault()
        console.log ("handlesubmit microform", microform)

        const options = {
            expirationMonth: expirationMonth,
            expirationYear: expirationYear
        };

        microform.createToken(options, function (err, token) {
            if (err) {
                // TODO: handle error
                console.error(err);
            } else {
                console.log("payment token", token);
                console.log("cart id", cart.id)

                placeOrderMutation({
                    variables: {
                        paymentData: {
                            cart_id: cart.id,
                            token: token,
                            card_type: "VI",
                            exp_date: expirationMonth + '-' + expirationYear
                        },
                        orderData: {
                            cart_id: cart.id
                        }
                    }
                }).then(data => {
                    console.log("place order mutation data", data)

                    if (data.data?.placeOrder?.payer_authentication?.parameters) {
                        const params = JSON.parse(data.data?.placeOrder?.payer_authentication?.parameters)
                        console.log("need payer auth", params)

                        Cardinal.continue('cca',
                            {
                                "AcsUrl": params.cca.AcsUrl,
                                "Payload": params.cca.Payload
                            },
                            {
                                "OrderDetails": params.order.OrderDetails
                            }
                        )

                    }
                })
            }
        })
    }

    return (
        <div>
            hello
            <form onSubmit={handleSubmit}>
                <label>Number: </label>
                <div id="number-container" />
                <label>Security code: </label>
                <div id="securityCode-container" />
                <select value={expirationMonth} onChange={handleExpirationMonthChange}>
                    <option value="01">January</option>
                    <option value="02">February</option>
                    <option value="03">March</option>
                    <option value="04">April</option>
                    <option value="05">May</option>
                    <option value="06">June</option>
                    <option value="07">July</option>
                    <option value="08">August</option>
                    <option value="09">September</option>
                    <option value="10">October</option>
                    <option value="11">November</option>
                    <option value="12">December</option>
                </select>
                <select value={expirationYear} onChange={handleExpirationYearChange}>
                    <option value="2021">2021</option>
                    <option value="2022">2022</option>
                    <option value="2023">2023</option>
                    <option value="2024">2024</option>
                    <option value="2025">2025</option>
                    <option value="2026">2026</option>
                    <option value="2027">2027</option>
                    <option value="2028">2028</option>
                </select>
                <input type="submit" value="Submit" />

            </form>
        </div>
    )
}
