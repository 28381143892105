import {useQuery, useMutation, gql} from "@apollo/client"

export function useCreateMicroformToken() {
    return useMutation(CREATE_MICROFORM_TOKEN_GQL)
}

export function usePlaceOrder() {
    return useMutation(PLACE_ORDER_GQL)
}

export function useCreateCardinalToken() {
    return useMutation(CREATE_CARDINAL_TOKEN_GQL)
}

export function usePlaceOrderWithCca() {
    return useMutation(RETRY_ORDER_GQL)
}

export function useRedeemQuotationWithCca() {
    return useMutation(RETRY_REDEEM_GQL);
}


const CREATE_MICROFORM_TOKEN_GQL = gql`
    mutation ($cart_id: String, $quotationId: String, $dpCode: String){
        createMicroformToken (cartId: $cart_id, quotationId: $quotationId, dpCode: $dpCode) {
            token
        }
    }
`

const PLACE_ORDER_GQL = gql`
    mutation ($paymentData: SetMicroformPaymentDataInput!, $orderData: PlaceOrderInput!) {
        setMicroformPaymentData(input: $paymentData) {
            success
        }

        placeOrder(input: $orderData) {
            order {
                order_number
            }
            payer_authentication {
                code
                parameters
            }
        }
    }
`

const CREATE_CARDINAL_TOKEN_GQL = gql`
    mutation ($requestData: CreateCardinalTokenInput) {
        createCardinalToken (input: $requestData) {
            token
        }
    }
`

const RETRY_ORDER_GQL = gql`
    mutation ($ccaData: SetPaymentCcaResponseInput, $orderData: PlaceOrderInput) {
        setPaymentCcaResponse (
            input: $ccaData
        ) {
            success
        }

        placeOrder(input: $orderData) {
            order {
                order_number

            }
            payer_authentication {
                code
                parameters
            }
        }
    }
`

const RETRY_REDEEM_GQL = gql`
    mutation ($ccaData: SetPaymentCcaResponseInput, $orderData: PlaceOrderInput) {
        setPaymentCcaResponse (
            input: $ccaData
        ) {
            success
        }

        redeemQuotation(input: $orderData) {
            order {
                order_number
            }
            payer_authentication {
                code
                parameters
            }
        }
    }
`
